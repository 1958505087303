import { z } from 'zod'
import { answerShape } from '../npo'
import { baseModelShape } from '../baseModel'
import { donorShape } from '../donor'
import { campaignShape } from '../campaign'
import { frequencyShape } from '../frequencies'
import { userShape } from '../users'

export const donationCreateShape = {
  amount: z.number().or(z.string()), //<-- response.contributionAmount
  donor: z.string(),
  campaign: z.string().uuid(),
  answers: z.array(z.string()).optional().nullable(),
  frequency: z.string().uuid().optional(),
  worldpayId: z.string().optional(),
  transactionId: z.string().optional(), // <-- response.submissionId
  consId: z.string().optional(),
  confirmationCode: z.string().optional(), // <-- response.confirmationID
  transactionErrorMsg: z.string().optional(),
  status: z.string().optional(),
  cardBrand: z.string().optional(),
  cardMaskedPan: z.string().optional(),
  cardExpirationDate: z.string().optional(),
  qrCode: z.string().optional(),
}

export const donationShape = {
  ...baseModelShape,
  amount: z.number(),
  donor: z.string(),
  donorRef: z.object(donorShape),
  campaign: z.object(campaignShape),
  fundraiser: z.object(userShape),
  answerRefs: z.array(z.object(answerShape)),
  answers: z.array(z.string()),
  frequency: z.object(frequencyShape),
  worldpayId: z.string(),
  transactionId: z.string(),
  transactionErrorMsg: z.string(),
  consId: z.string(),
  confirmationCode: z.string(),
  status: z.string(),
  cardBrand: z.string(),
  qrCode: z.string(),
}

export const donationFiltersShape = {
  ordering: z.string().optional(),
  campaign: z.string(),
  dateRangeStart: z.date().or(z.string()),
  dateRangeEnd: z.date().or(z.string()),
  fundraiser: z.string(),
  frequency: z.string(),
  status: z.string(),
}
